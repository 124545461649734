import {
  IOrder,
  IOrderAddEdit,
  IOrderBillingData,
  IOrderReport,
  IOrderSuspensionDate,
  IOrderUpdate
} from 'interfaces/IOrder';
import { useCallback, useMemo } from 'react';
import { useFetch } from 'services/useFetch';

interface IResponseOrder {
  ProductContracts: IOrder[];
  ProductContractsMain: IOrder[];
  TotalPriceProducts: number;
  TotalClientAdjustments: number;
}

interface IResponseOrderBilling {
  ProductContractsBilling: IOrderBillingData;
  ProductContractsBillingEstimated: IOrderBillingData;
  ProductContractsBillingPreviousMonth: IOrderBillingData;
}

interface IResponseOrdersReport {
  ProductContractReports: IOrderReport[];
  FileName: string;
}

export const useOrderService = () => {
  const { callApi } = useFetch();

  const findByClientId = useCallback(
    async (id: number) => {
      const result = await callApi<IResponseOrder>({
        url: `ProductContract/FindProductContractByClientId/${id}`,
        method: 'get',
        prefixMsgError: 'error in useOrderService - findByClientId:'
      });
      return result;
    },
    [callApi]
  );

  const findOrderBillingByOrderId = useCallback(
    async (id: number) => {
      const result = await callApi<IResponseOrderBilling>({
        url: `ProductContract/findProductContractBillingById/${id}`,
        method: 'get',
        prefixMsgError: 'error in useOrderService - findOrderBillingByOrderId:'
      });
      return result;
    },
    [callApi]
  );

  const getOrdersReport = useCallback(
    async (startDate: string) => {
      const result = await callApi<IResponseOrdersReport>({
        url: `ProductContract/GetProductContractReport/${startDate}`,
        method: 'get',
        prefixMsgError: 'error in useOrderService - GetOrdersReport:'
      });
      return result;
    },
    [callApi]
  );

  const addEditOrder = useCallback(
    async (order: IOrderAddEdit) => {
      await callApi({
        url: 'ProductContract/AddEditProductContract',
        method: 'post',
        data: order,
        prefixMsgError: 'error in OrderService - addEdit:'
      });
    },
    [callApi]
  );

  const updateOrder = useCallback(
    async (orderUpdate: IOrderUpdate[], clientId: number) => {
      await callApi<IResponseOrderBilling>({
        url: `ProductContract/UpdateProductContract/${clientId}`,
        method: 'put',
        data: orderUpdate,
        prefixMsgError: 'error in useOrderService - updateOrder:'
      });
    },
    [callApi]
  );

  const updateSuspensionDate = useCallback(
    async (
      orderSuspensionDateUpdate: IOrderSuspensionDate[],
      clientId: number
    ) => {
      await callApi({
        url: `ProductContract/UpdateProductContract/${clientId}/BillingStartDate`,
        method: 'put',
        data: orderSuspensionDateUpdate,
        prefixMsgError: 'error in useOrderService - updateSuspensionDate:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      orderService: {
        findByClientId,
        findOrderBillingByOrderId,
        addEditOrder,
        updateOrder,
        getOrdersReport,
        updateSuspensionDate
      }
    }),
    [
      findByClientId,
      findOrderBillingByOrderId,
      addEditOrder,
      updateOrder,
      getOrdersReport,
      updateSuspensionDate
    ]
  );
};
