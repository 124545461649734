import { Add } from '@styled-icons/fluentui-system-regular';
import Allow from 'components/Allow';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TableProductBillings from './Components/TableProductBillings';
import { Container } from './styles';

export const ProductBilling: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Modo de Faturamento do Produtos</Title>
      <br />
      <TableProductBillings />
    </Container>
  );
};
