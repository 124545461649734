import Allow from 'components/Allow';
import FilterText from 'components/FilterText';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import { IClientAdjustment } from 'interfaces/IClientAdjustment';
import { IClient } from 'interfaces/IClient';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClientAdjustmentService } from 'services/clientAdjustmentService';
import * as S from './style';

const TableClientAdjustments: React.FC = () => {
  const client = useLocation().state as IClient;
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const { clientAdjustmentService } = useClientAdjustmentService();
  const [clientAdjustments, setClientAdjustments] = useState<
    IClientAdjustment[] | null
  >(null);
  const getData = useCallback(async () => {
    try {
      const { ClientAdjustments } =
        await clientAdjustmentService.findClientAdjustmentByClientId(client.Id);
      setClientAdjustments(ClientAdjustments);
    } catch (error) {
      console.log(
        `error in TableClientAdjustments - function getData, error:${error}`
      );
    }
  }, [clientAdjustmentService, client]);
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Competência, Valor, Data..."
        nameFieldToFilter="ClientAdjustmentId,CompetenceMonth,CompetenceYear,Competence,Value,CreatedDate,User"
        dataToFilter={clientAdjustments}
        setDataToFilter={setClientAdjustments}
      />
      {clientAdjustments && clientAdjustments.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Competência</th>
                    <th>Valor</th>
                    <th>Observação</th>
                    <th>Data de Cadastro</th>
                    <th>Usuário</th>
                  </tr>
                </thead>
                <tbody>
                  {clientAdjustments.map(
                    (clientAdjustment: IClientAdjustment) => (
                      <tr key={clientAdjustment.ClientAdjustmentId}>
                        <td>{clientAdjustment.ClientAdjustmentId}</td>
                        <td>
                          {`${clientAdjustment.CompetenceMonth}-${clientAdjustment.CompetenceYear}`}
                        </td>
                        <td>{clientAdjustment.Value}</td>
                        <td>{clientAdjustment.Observation}</td>
                        <td>{clientAdjustment.CreatedDate}</td>
                        <td>{clientAdjustment.User}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableClientAdjustments;
