import Allow from 'components/Allow';
import FilterText from 'components/FilterText';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import { IProductBilling } from 'interfaces/IProduct';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useProductBillingService } from 'services/productBillingService';
import { Delete, Edit } from 'styled-icons/material';
import * as S from './style';

const TableProductBillings: React.FC = () => {
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const { productBillingService } = useProductBillingService();
  const [productBillings, setProductBillings] = useState<
    IProductBilling[] | null
  >(null);
  const getData = useCallback(async () => {
    try {
      const { ProductBillings } = await productBillingService.getAll();
      setProductBillings(ProductBillings);
    } catch (error) {
      console.log(
        `error in TableProductBillings - function getData, error:${error}`
      );
    }
  }, [productBillingService]);
  const handleConfirm = useCallback(
    async (productId: number) => {
      try {
        await productBillingService.destroy(productId);
        const { ProductBillings } = await productBillingService.getAll();
        setProductBillings(ProductBillings);
      } catch (error) {
        console.log(
          `error in TableProductBillings - function handleConfirm, error:${error}`
        );
      }
    },
    [productBillingService]
  );
  const handleDelete = (productBillingId: number) => {
    setDialog({
      show: true,
      callBackConfirm: () => handleConfirm(productBillingId),
      textBody: 'Esta ação é irreversível, tem certeza que deseja prosseguir ?'
    });
  };
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Id ou Nome..."
        nameFieldToFilter="Name,Id"
        dataToFilter={productBillings}
        setDataToFilter={setProductBillings}
      />
      {productBillings && productBillings.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  {productBillings.map((productBilling: IProductBilling) => (
                    <tr key={productBilling.Id}>
                      <td>{productBilling.Id}</td>
                      <td>{productBilling.Name}</td>
                      <td>{productBilling.Description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableProductBillings;
