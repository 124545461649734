import Allow from 'components/Allow';
import Logout from 'components/Logout';
import Profile from 'components/Profile';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import React, { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import * as S from './styles';

const Menu: React.FC = () => {
  const { showMenu, setShowMenu } = useMainContext();
  const handleShowMenu = useCallback(() => {
    setShowMenu(prevState => !prevState);
  }, [setShowMenu]);
  const [submenu, setSubmenu] = useState('');

  const handleClickMenu = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setShowMenu(prevState => !prevState);
    },
    [setShowMenu]
  );
  const handleSubmenu = useCallback(
    (event: React.MouseEvent<HTMLElement>, submenuName: string) => {
      event.preventDefault();
      setSubmenu(prevState => (prevState === submenuName ? '' : submenuName));
    },
    [setSubmenu]
  );
  return (
    <>
      <S.BackGround showMenu={showMenu} onClick={handleShowMenu} />
      <S.WrapperNav showMenu={showMenu}>
        <S.Nav>
          <ul>
            <Allow ruleId={enumRules.Client.CanReadClient}>
              <li>
                <NavLink to="/client" onClick={handleClickMenu}>
                  Cliente
                </NavLink>
              </li>
            </Allow>
            <Allow ruleId={enumRules.Report.CanReadReportBilling}>
              <li>
                <NavLink to="/reportBilling" onClick={handleClickMenu}>
                  Relatório Billing
                </NavLink>
              </li>
            </Allow>
            <Allow ruleId={enumRules.Admin}>
              <li>
                <a href="#admin" onClick={e => handleSubmenu(e, 'admin')}>
                  Administrativo
                </a>

                {submenu === 'admin' && (
                  <ul className="submenu">
                    <li>
                      <NavLink to="/users" onClick={handleClickMenu}>
                        Usuários
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/rules" onClick={handleClickMenu}>
                        Regras
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/groupRules" onClick={handleClickMenu}>
                        Grupo de Regras
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
            </Allow>
            <Allow
              ruleId={
                enumRules.Product.CanReadProduct ||
                enumRules.ProductType.CanReadProductType ||
                enumRules.ProductGroup.CanReadProductGroup ||
                enumRules.ProductPrice.CanReadProductPrice
              }
            >
              <li>
                <a href="#products" onClick={e => handleSubmenu(e, 'products')}>
                  Produtos
                </a>

                {submenu === 'products' && (
                  <ul className="submenu">
                    <Allow ruleId={enumRules.Product.CanReadProduct}>
                      <li>
                        <NavLink
                          to="/product/catalog"
                          onClick={handleClickMenu}
                        >
                          Catálogo
                        </NavLink>
                      </li>
                    </Allow>
                    <Allow ruleId={enumRules.ProductType.CanReadProductType}>
                      <li>
                        <NavLink to="/product/type" onClick={handleClickMenu}>
                          Tipo
                        </NavLink>
                      </li>
                    </Allow>
                    <Allow ruleId={enumRules.ProductGroup.CanReadProductGroup}>
                      <li>
                        <NavLink to="/product/group" onClick={handleClickMenu}>
                          Grupo
                        </NavLink>
                      </li>
                    </Allow>
                    <Allow
                      ruleId={enumRules.ProductBilling.CanReadProductBilling}
                    >
                      <li>
                        <NavLink
                          to="/product/billing"
                          onClick={handleClickMenu}
                        >
                          Faturamento
                        </NavLink>
                      </li>
                    </Allow>
                    <Allow ruleId={enumRules.ProductPrice.CanReadProductPrice}>
                      <li>
                        <NavLink to="/product/price" onClick={handleClickMenu}>
                          Preço
                        </NavLink>
                      </li>
                    </Allow>
                  </ul>
                )}
              </li>
            </Allow>
            <li className="logout">
              <Profile className="menu" />
              <Logout className="menu logout-menu" />
            </li>
          </ul>
        </S.Nav>
      </S.WrapperNav>
    </>
  );
};

export default Menu;
