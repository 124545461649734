import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import { IProductBilling } from 'interfaces/IProduct';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProductBillingService } from 'services/productBillingService';
import { Container } from './styles';

const AddEditProductBilling: React.FC = () => {
  const ruleEdit = useLocation().state as IProductBilling;
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const [validated, setValidated] = useState(false);
  const { productBillingService } = useProductBillingService();
  const [productBillingAddEdit, setProductBillingAddEdit] =
    useState<IProductBilling>({
      Name: ''
    } as IProductBilling);

  const handleConfirm = useCallback(async () => {
    try {
      if (ruleEdit?.Id) {
        await productBillingService.update(productBillingAddEdit);
      } else await productBillingService.add(productBillingAddEdit);
      navigate(-1);
    } catch (error) {
      console.log(`error in AddRule - function handleConfirm, error:${error}`);
    }
  }, [ruleEdit?.Id, productBillingService, productBillingAddEdit, navigate]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
      setValidated(!form.checkValidity());

      if (form.checkValidity())
        setDialog({ show: true, callBackConfirm: handleConfirm });
    },
    [handleConfirm, setDialog]
  );

  useEffect(() => {
    if (ruleEdit?.Id) {
      setProductBillingAddEdit(ruleEdit);
    }
  }, [ruleEdit]);

  return (
    <Container>
      <Title backButton>
        {' '}
        {ruleEdit?.Id ? 'Editar' : 'Adicionar'} Grupo de Produto{' '}
      </Title>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} xs={12} controlId="validationName">
            <Form.Label>
              <h3>Nome</h3>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              placeholder="Nome"
              defaultValue={productBillingAddEdit?.Name}
              onChange={e =>
                setProductBillingAddEdit(prevState => ({
                  ...prevState,
                  Name: e.target.value
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Nome é Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={12}>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
      <Row />
    </Container>
  );
};

export default AddEditProductBilling;
