import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useMainContext } from 'context/MainContext';
import { IUserProfile } from 'interfaces/IUserProfile';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import React, { useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { useAuthService } from 'services/authService';
import * as S from './styles';

interface ResponseUser {
  name: string;
  picture: string;
  sub: string;
  email: string;
}

const Login: React.FC = () => {
  const { profile, setProfile } = useMainContext();
  const [notAuthorized, setNotAuthorized] = useState(false);
  const { authService } = useAuthService();

  const handlerLoginError = useCallback(() => {
    console.log('error in Login - handlerLoginError');
  }, []);

  const handleLogin = useCallback(
    async (response: CredentialResponse) => {
      setNotAuthorized(false);

      try {
        if (response) {
          const userObject = jwt_decode(response.credential as string);
          const { name, picture, sub, email } = userObject as ResponseUser;

          const user: IUserProfile = {
            Email: email,
            Name: name || '',
            MicrosoftId: sub
          };

          const result = (JSON.parse(
            process.env.REACT_APP_MOCHA_LOGIN || ''
          ) as boolean)
            ? await authService.loginMocha(user)
            : await authService.login(user);

          setProfile(result);
        } else {
          setNotAuthorized(true);
        }
      } catch (error) {
        console.log(`error in Loging - function handleLogin, error:${error}`);
      }
    },
    [authService, setProfile]
  );

  if (profile) return <Navigate to="/" replace />;

  return (
    <S.Container>
      <S.WrapperIconBackground>
        <img alt="logo" src="images/img-background-login.svg" />
      </S.WrapperIconBackground>
      <S.WrapperLogin>
        <S.Logo>
          <h1>Admin</h1>
          <img alt="logo" src="images/Logo_Golden_fundopreto01.png" />
        </S.Logo>
        <S.WrapperButton>
          <GoogleLogin onSuccess={handleLogin} onError={handlerLoginError} />
        </S.WrapperButton>
        {notAuthorized && <Alert variant="danger">Acesso Negado</Alert>}
      </S.WrapperLogin>
    </S.Container>
  );
};

export default Login;
