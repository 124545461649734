import { IOrderReport } from 'interfaces/IOrder';
import React, { useCallback, useState } from 'react';
import { Button, Col, FormControl, Row, Table } from 'react-bootstrap';
import { useOrderService } from 'services/orderService';
import { FileExcel } from 'styled-icons/icomoon';
import Title from '../../components/Title';
import * as S from './styles';

const ReportBilling: React.FC = () => {
  const today = new Date();
  const lastMonths = today.addMonths(-24);
  const lastMonth = today.addMonths(-1);
  const minDate = lastMonths.toYearMonth();
  const maxDate = lastMonth.toYearMonth();
  const [hrefReport, setHrefReport] = useState('#');
  const [ordersReport, setOrdersReport] = useState<IOrderReport[] | null>(null);
  const [startDate, setStartDate] = useState<string>(
    `${lastMonth.toYearMonth()}-01`
  );
  const { orderService } = useOrderService();

  const handleReport = useCallback(
    async async => {
      const { FileName, ProductContractReports } =
        await orderService.getOrdersReport(startDate);

      const baseUrl = process.env.REACT_APP_REST_API_BASE_NAME;
      const url = `${baseUrl}ProductContract/DownloadProductContractReport?request=${FileName}`;
      setHrefReport(url);
      setOrdersReport(ProductContractReports);
    },
    [startDate, orderService]
  );

  return (
    <S.Container>
      <Title>Relatório Billing</Title>
      <Row className="justify-content-center">
        <Col xs={12} sm={7} md={5} lg={4} xl={3}>
          <FormControl
            type="month"
            min={minDate}
            max={maxDate}
            defaultValue={maxDate}
            pattern="[0-9]{4}-[0-9]{2}"
            onChange={e => setStartDate(`${e.currentTarget.value}-01`)}
          />
        </Col>
        <Col xs={12} sm={5} md={4} lg={3}>
          <Button variant="primary" onClick={handleReport}>
            Gerar Relatório
          </Button>
        </Col>
      </Row>
      {ordersReport && (
        <div>
          <br />
          <br />
          <br />
          <Button variant="success" href={hrefReport} target="_blank">
            Download <FileExcel width="15px" />
          </Button>
          <br />
          <br />
          <Title className="border-top h2-title">Relatório Gerado</Title>
          <div className="table-thead-fixed">
            <Table className="table-striped text-center" responsive>
              <thead>
                <tr>
                  <th>Cliente </th>
                  <th>Companhia </th>
                  <th>VM </th>
                  <th>VM Dt. Final</th>
                  <th>Ambiente</th>
                  <th>Produto </th>
                  <th>Modo de faturamento </th>
                  <th>Grupo </th>
                  <th>Vlr Unit. Mensal - CUSTO </th>
                  <th>Vlr Unit. Mensal - VENDA </th>
                  <th>Dt. Inicial </th>
                  <th>Dt. Final </th>
                  <th>Billing Dt. Liberação</th>
                  <th>Billing Dt. Inicial </th>
                  <th>Billing Dt. Final </th>
                  <th>Quantidade </th>
                  <th>Total Horas </th>
                  <th>Vlr Período - CUSTO</th>
                  <th>Vlr Período - VENDA</th>
                  <th>Usuário </th>
                </tr>
              </thead>
              <tbody>
                {ordersReport.map((order: IOrderReport, index: number) => (
                  <tr key={index.toString()}>
                    <td>{order.Client}</td>
                    <td>{order.Company}</td>
                    <td>{order.VM}</td>
                    <td>{order.vmEndDateFmt}</td>
                    <td>{order.Cloud}</td>
                    <td>{order.Product}</td>
                    <td>{order.ProductBillingName}</td>
                    <td>{order.ProductGroupName}</td>
                    <td>
                      {order.CostMonthlyUnitValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {order.PriceMonthlyUnitValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>{order.StartDateFmt}</td>
                    <td>{order.EndDateFmt}</td>
                    <td>{order.BillingReleaseStartDateFmt}</td>
                    <td>{order.BillingStartDateFmt}</td>
                    <td>{order.BillingEndDateFmt}</td>
                    <td>
                      {order.Quantity.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {order.FullHours.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {order.CostPeriodValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>
                      {order.PricePeriodValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>
                    <td>{order.User}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </S.Container>
  );
};

export default ReportBilling;
