import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import { IOrder, IOrderAddEdit } from 'interfaces/IOrder';
import { IProduct } from 'interfaces/IProduct';
import { IClient } from 'interfaces/IClient';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, InputGroup } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrderService } from 'services/orderService';
import { useProductService } from 'services/productService';
import { masks } from 'utils/masks';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Container } from './styles';
import * as S from './styles';

interface IStateProps {
  client: IClient;
  order: IOrder;
}

const AddEditOrder: React.FC = () => {
  const navigate = useNavigate();
  const { setDialog, profile } = useMainContext();
  const { client, order } = useLocation().state as IStateProps;
  const [validated, setValidated] = useState(false);
  const { orderService } = useOrderService();
  const { productService } = useProductService();
  const [orderAddEdit, setOrderAddEdit] = useState<IOrderAddEdit>(
    {} as IOrderAddEdit
  );
  const [products, setProducts] = useState<IProduct[]>([] as IProduct[]);
  const [canceledDate, setCanceledDate] = useState<Date>();
  const [highlightDate] = useState<Date>(new Date());
  const [createdDate, setCreatedDate] = useState<Date>(
    order?.Id
      ? new Date(
          parseInt(order.CreatedDate.toString().split('/')[2], 10),
          parseInt(order.CreatedDate.toString().split('/')[1], 10) - 1,
          parseInt(order.CreatedDate.toString().split('/')[0], 10)
        )
      : new Date()
  );
  const [billingStartDate, setBillingStartDate] = useState<Date>(
    order?.Id
      ? new Date(
          parseInt(order.BillingStartDate.toString().split('/')[2], 10),
          parseInt(order.BillingStartDate.toString().split('/')[1], 10) - 1,
          parseInt(order.BillingStartDate.toString().split('/')[0], 10)
        )
      : new Date()
  );
  const [price, setPrice] = useState<number>(
    order?.Id ? order.Price / order.Quantity : Number
  );

  const ReactDatePickerInput = React.forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  >((props, ref) => (
    <S.CustomInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
    />
  ));

  const getData = useCallback(async () => {
    try {
      const { Products } = await productService.getAll();
      setProducts(
        Products.filter(x => x.ProductBillingName.toLowerCase() === 'manual')
      );
    } catch (error) {
      console.log(`error in TableProducts - function getData, error:${error}`);
    }
  }, [productService]);

  const handleConfirm = useCallback(async () => {
    try {
      orderAddEdit.ProductContractId = order?.Id;
      orderAddEdit.ClientId = client.Id;

      if (orderAddEdit.Quantity === undefined) {
        orderAddEdit.Quantity = order?.Quantity;
      }

      if (orderAddEdit.Price === undefined) {
        orderAddEdit.Price = price;
      }

      if (orderAddEdit.ProductId === undefined) {
        orderAddEdit.ProductId = order?.ProductId;
      }

      orderAddEdit.CreatedDate = createdDate;
      orderAddEdit.BillingStartDate = billingStartDate;
      orderAddEdit.CanceledDate = canceledDate;
      orderAddEdit.Email = profile?.Email;

      await orderService.addEditOrder(orderAddEdit);

      navigate(-1);
    } catch (error) {
      console.log(`error in AddRule - function handleConfirm, error:${error}`);
    }
  }, [
    client.Id,
    price,
    createdDate,
    billingStartDate,
    canceledDate,
    profile?.Email,
    order,
    orderService,
    orderAddEdit,
    navigate
  ]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
      setValidated(!form.checkValidity());

      if (form.checkValidity())
        setDialog({ show: true, callBackConfirm: handleConfirm });
    },
    [handleConfirm, setDialog]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      <Title backButton> {order?.Id ? 'Editar' : 'Adicionar'} Pedido </Title>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationProduct">
            <Form.Label>Produto</Form.Label>
            <Form.Select
              name="select"
              required
              onChange={a =>
                setOrderAddEdit(prevState => ({
                  ...prevState,
                  ProductId: Number(a.target.value)
                }))
              }
              defaultValue={order?.ProductId || 0}
            >
              <option
                selected={order?.ProductId === null}
                label="Selecione"
                value={undefined}
              />
              {products.map(a => (
                <option
                  selected={order?.ProductId === a.Id}
                  key={a.Id}
                  value={a.Id}
                >
                  {a.Name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Produto é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} xs={6} controlId="validationName">
            <Form.Label>
              <h3>Quantidade</h3>
            </Form.Label>
            <Form.Control
              type="number"
              step=".01"
              name="quantity"
              required
              placeholder="Quantidade"
              defaultValue={order?.Quantity}
              onChange={e =>
                setOrderAddEdit(prevState => ({
                  ...prevState,
                  Quantity: Number(e.target.value)
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Quantidade é Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="validationName">
            <Form.Label>
              <h3>Preço</h3>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>R$</InputGroup.Text>
              <Form.Control
                type="number"
                inputMode="numeric"
                min="0"
                step=".01"
                name="price"
                required
                placeholder="Preço"
                defaultValue={price}
                onChange={e => {
                  masks.currency(e as React.FormEvent<HTMLInputElement>);
                  setOrderAddEdit(prevState => ({
                    ...prevState,
                    Price: Number(e.target.value)
                  }));
                }}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Campo Preço é Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} xs={6} controlId="validationName">
            <Form.Label>
              <h3>Data de cadastro</h3>
            </Form.Label>
            <ReactDatePicker
              highlightDates={[highlightDate]}
              customInput={<ReactDatePickerInput />}
              dateFormat="dd/MM/yyyy"
              selected={createdDate}
              onChange={(date: Date) => setCreatedDate(date)}
              closeOnScroll
              disabled
            />
            <Form.Control.Feedback type="invalid">
              Campo Data de cadastro é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="validationName">
            <Form.Label>
              <h3>Data de ínicio de Faturamento</h3>
            </Form.Label>
            <ReactDatePicker
              highlightDates={[highlightDate]}
              customInput={<ReactDatePickerInput />}
              dateFormat="dd/MM/yyyy"
              selected={billingStartDate}
              onChange={(date: Date) => setBillingStartDate(date)}
              closeOnScroll
            />
            <Form.Control.Feedback type="invalid">
              Campo Data de ínicio de Faturamento é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs={6} controlId="validationName">
            <Form.Label>
              <h3>Data de Cancelamento</h3>
            </Form.Label>
            <ReactDatePicker
              highlightDates={[highlightDate]}
              customInput={<ReactDatePickerInput />}
              dateFormat="dd/MM/yyyy"
              selected={canceledDate}
              onChange={(date: Date) => setCanceledDate(date)}
              disabled={order === undefined}
              closeOnScroll
            />
            <span style={{ fontSize: '0.8em' }}>
              Atenção: Após preenchido/salvo o pedido automáticamente será
              encerrado e não será mais visto na listagem principal
            </span>
          </Form.Group>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
      <Row />
    </Container>
  );
};

export default AddEditOrder;
