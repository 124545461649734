import { Add, Money } from '@styled-icons/fluentui-system-regular';
import { Block, Edit } from '@styled-icons/boxicons-regular';
import FilterText from 'components/FilterText';
import { LabelCustom } from 'components/LabelCustom';
import { TextCustom } from 'components/TextCustom';
import Allow from 'components/Allow';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import { useMainContext } from 'context/MainContext';
import { IClient } from 'interfaces/IClient';
import { IOrder, IOrderSuspensionDate } from 'interfaces/IOrder';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  FormLabel,
  OverlayTrigger,
  Popover,
  Row,
  Table
} from 'react-bootstrap';

import Modal from 'react-bootstrap/Modal';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useLocation, useNavigate } from 'react-router-dom';
import { useOrderService } from 'services/orderService';
import { InfoSquare } from 'styled-icons/boxicons-solid';
import { Search } from 'styled-icons/icomoon';
import PopoverBodyOrder from './components/PopoverBodyOrder';
import * as S from './styles';

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const { setDialog } = useMainContext();

  const client = useLocation().state as IClient;
  // modal var
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [highlightDate, setHighlightDate] = useState<Date>(new Date());
  const [selectedId, setSelectedId] = useState<number>(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const openModal = (object: IOrder) => {
    handleShow();
    const splitDate = object.BillingStartDate.toLocaleString()
      .split('/')
      .map(a => Number(a));
    const l = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
    setHighlightDate(l);
    setSelectedId(object.Id as number);
  };

  const [orders, setOrders] = useState<IOrder[] | null>(null);
  const [totalAllOrders, setTotalAllOrders] = useState<number>(0);
  const [totalClientAdjustments, setTotalClientAdjustments] =
    useState<number>(0);
  const [ordersMain, setOrdersMain] = useState<IOrder[] | null>(null);
  const [ordersMainFiltered, setOrdersMainFiltered] = useState<IOrder[] | null>(
    null
  );

  const { orderService } = useOrderService();

  const ReactDatePickerInput = React.forwardRef<
    HTMLInputElement,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  >((props, ref) => (
    <S.CustomInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
    />
  ));

  const getData = useCallback(async () => {
    if (client.Id && !ordersMain) {
      try {
        const {
          ProductContractsMain,
          ProductContracts,
          TotalPriceProducts,
          TotalClientAdjustments
        } = await orderService.findByClientId(client.Id);
        setOrders(ProductContracts);
        setOrdersMain(ProductContractsMain);
        setOrdersMainFiltered(ProductContractsMain);
        setTotalAllOrders(TotalPriceProducts);
        setTotalClientAdjustments(TotalClientAdjustments);
      } catch (error) {
        console.log(`error in Orders - function getDate, error:${error}`);
      }
    }
  }, [orderService, ordersMain, client.Id]);

  const handleSumOrders = useCallback(
    (orderId: number) => {
      let total = 0;
      if (orderId && orders) {
        total = orders?.filter(
          x => x.ProductContractUpperId === orderId
        ).length;
      }
      const orderMain = ordersMainFiltered?.filter(o => o.Id === orderId);
      const quantity = orderMain ? orderMain[0].Quantity : undefined;

      if (total === 0 && quantity !== undefined) {
        total = quantity;
      }
      return total;
    },
    [orders, ordersMainFiltered]
  );

  const handleConfirm = useCallback(async () => {
    const object: IOrderSuspensionDate = {
      ClientId: client.Id,
      ProductContractId: selectedId,
      BillingStartDate: startDate
    };
    try {
      await orderService.updateSuspensionDate([object], client.Id);
      const { ProductContractsMain } = await orderService.findByClientId(
        client.Id
      );
      setOrdersMainFiltered(ProductContractsMain);
      setStartDate(new Date());
      setShow(false);
    } catch (error) {
      console.log(
        `error in UpdateSuspensionDate - function handleConfirm, error:${error}`
      );
    }
  }, [startDate, selectedId, client.Id, orderService]);

  const handleSubmit = useCallback(() => {
    handleClose();
    setDialog({
      show: true,
      callBackConfirm: handleConfirm,
      callBackCancel: handleShow
    });
  }, [handleConfirm, setDialog]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Suspensão de Faturamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <FormLabel>Data:</FormLabel>
            <ReactDatePicker
              minDate={new Date()}
              highlightDates={[highlightDate]}
              customInput={<ReactDatePickerInput />}
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      <Title backButton>Pedidos</Title>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Companhia</LabelCustom>
          <TextCustom>{client.CompanyName}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>CNPJ</LabelCustom>
          <TextCustom>{client.Cnpj}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Razão Social</LabelCustom>
          <TextCustom>{client.Name}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Data de Cadastro</LabelCustom>
          <TextCustom>{client.CreatedDate}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Nome Fantasia</LabelCustom>
          <TextCustom>{client.FantasyName}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Descontos/Acréscimos</LabelCustom>
          <TextCustom>R$ {totalClientAdjustments.toLocaleString()}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>N. Pedidos</LabelCustom>
          <TextCustom>{client.OrdersNumber}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Total</LabelCustom>
          <TextCustom>R$ {totalAllOrders.toLocaleString()}</TextCustom>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12} sm={6}>
          <Allow ruleId={enumRules.Order.CanWriteOrder}>
            <Button
              variant="outline-primary"
              title="Adicionar Pedido"
              onClick={() =>
                navigate('addEditOrder', {
                  state: {
                    client
                  }
                })
              }
            >
              <Add size="20" />
            </Button>
          </Allow>
          &nbsp;&nbsp;&nbsp;
          <Allow ruleId={enumRules.ClientAdjustment.CanReadClientAdjustment}>
            <Button
              variant="outline-primary"
              title="Ajustes financeiros por cliente(Descontos / Acréscimos)"
              onClick={() =>
                navigate(`/clientDetails/${client.Id}/adjustments`, {
                  state: client
                })
              }
            >
              <Money size="20" />
            </Button>
          </Allow>
        </Col>
      </Row>
      <br />
      <FilterText
        setDataToFilter={setOrdersMainFiltered}
        nameFieldToFilter="ServerName"
        dataToFilter={ordersMain}
        placeholderInput="Nome Servidor"
      />
      {ordersMainFiltered !== null && ordersMainFiltered.length > 0 && (
        <Row>
          <Col xs={12}>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead className="">
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Valor</th>
                    <th>Qtde. Itens</th>
                    <th>Suspenso Até</th>
                    <th>Data de Cadastro</th>
                    <th> </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersMainFiltered.map((order: IOrder, index: number) => (
                    <tr key={order.Id}>
                      <td>{order.Id}</td>
                      <td>
                        {order.ServerName === '' || order.ServerName === null
                          ? order.ProductName
                          : `${order.ProductName}-${order.ServerName}`}
                      </td>
                      <td>
                        {order.Price.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                        &nbsp;
                        <OverlayTrigger
                          key={order.Id}
                          placement="top"
                          trigger={['click', 'hover']}
                          overlay={
                            <Popover>
                              <Popover.Header as="h4">
                                {order.ProductName}
                              </Popover.Header>
                              <Popover.Body>
                                <PopoverBodyOrder
                                  order={order}
                                  orders={orders}
                                />
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <InfoSquare size="20" />
                        </OverlayTrigger>
                      </td>
                      <td>{handleSumOrders(order.Id)}</td>
                      <td>{order.BillingStartDate}</td>
                      <td>{order.CreatedDate}</td>
                      <td>
                        {order.ProductBillingId === 2 ? (
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              navigate(`/orderDetail/${order.Id}`, {
                                state: {
                                  client,
                                  order
                                }
                              })
                            }
                          >
                            <Search title="Detalhar" size={22} />
                          </Button>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {order.ProductBillingId === 1 ? (
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              navigate('addEditOrder', {
                                state: {
                                  client,
                                  order
                                }
                              })
                            }
                          >
                            <Edit title="Editar Pedido" size={22} />
                          </Button>
                        ) : (
                          <Button
                            variant="outline-primary"
                            onClick={() => openModal(order)}
                          >
                            <Block title="Data de Suspensão" size={22} />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};
export default Orders;
