import * as React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Container, StyledCard, Title } from './styles';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  callBackConfirm: () => Promise<unknown>;
  choice: boolean;
  setChoice: React.Dispatch<React.SetStateAction<boolean>>;
};
const ChoiceComponent: React.FC<Props> = ({
  show,
  setShow,
  setChoice,
  choice,
  callBackConfirm
}) => {
  const handleShow = React.useCallback(() => {
    callBackConfirm();
    setShow(false);
  }, [callBackConfirm, setShow]);

  const handleClose = React.useCallback(() => {
    setShow(false);
  }, [setShow]);

  return (
    <Container show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="alert-secondary">
        <h2 className="alert-secondary">Confirmar Operação?</h2>
      </Modal.Header>

      <Modal.Body className="modal-body-max-height">
        <p>Como deseja aplicar esta alteração ?</p>
        <Row>
          <Col onClick={() => setChoice(choice)}>
            <StyledCard selected={choice}>
              <Row>
                <Col>
                  <Title>Alterar Histórico</Title>
                  <p>
                    Desse modo, além de alterar os pedidos novos, as alterações
                    serão aplicadas também a todos os pedidos existentes.
                  </p>
                </Col>
              </Row>
            </StyledCard>
          </Col>
          {/* <Col onClick={() => setChoice(false)}>
            <StyledCard selected={choice}>
              <Row>
                <Col>
                  <Title>Alterar Novos</Title>
                  <p>
                    Desse modo, as alterações serão aplicadas somente em pedidos
                    novos. O histórico permanecerá com os valores inalterados.
                  </p>
                </Col>
              </Row>
            </StyledCard>
          </Col> */}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>

        <Button variant="primary" onClick={handleShow}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Container>
  );
};
export default ChoiceComponent;
