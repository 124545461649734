import Dialog from 'components/Dialog';
import Loading from 'components/Loading';
import { IDialog } from 'interfaces/IDialog';
import { IUserProfile } from 'interfaces/IUserProfile';

import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useAuthService } from 'services/authService';

interface IMainContext {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setProfile: React.Dispatch<React.SetStateAction<IUserProfile | null>>;
  showMenu: boolean;
  profile: IUserProfile | null;
  isAuthenticated: () => boolean;
  setDialog: React.Dispatch<React.SetStateAction<IDialog>>;
  dialog: IDialog;
}

const MainContext = createContext<IMainContext>({} as IMainContext);

export const MainContextProvider: React.FC = ({ children }) => {
  const [working, setWorking] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [profile, setProfile] = useState<IUserProfile | null>(null);
  const [dialog, setDialog] = useState<IDialog>({
    callBackConfirm: () => {},
    callBackCancel: () => {},
    show: false,
    textBody: '',
    textError: [] as string[],
    textTitle: '',
    isAlert: false
  } as IDialog);
  const { authService } = useAuthService();

  const isAuthenticated = useCallback(
    () => !!(profile && !!sessionStorage.getItem('refresh-token')),
    [profile]
  );

  const values = useMemo(
    () => ({
      setLoading,
      isAuthenticated,
      profile,
      setProfile,
      setShowMenu,
      showMenu,
      setDialog,
      dialog
    }),
    [dialog, isAuthenticated, profile, showMenu]
  );

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const resultProfile = (JSON.parse(
          process.env.REACT_APP_MOCHA_LOGIN || ''
        ) as boolean)
          ? await authService.getRefreshTokenMocha()
          : await authService.getRefreshToken();

        setProfile(resultProfile?.user || null);
      } catch (error) {
        console.log(
          `error in MainContextProvider - function refreshToken, error:${error}`
        );
        setProfile(null);
      } finally {
        setWorking(false);
      }
    };
    if (!profile) refreshToken();
  }, [authService, profile, setProfile]);

  return (
    <MainContext.Provider value={values}>
      <Loading show={loading} />
      <Dialog />
      {working ? null : children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => React.useContext(MainContext);
