import Allow from 'components/Allow';
import { Card } from 'components/Card';
import { LabelCustom } from 'components/LabelCustom';
import { TextCustom } from 'components/TextCustom';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import { IClient } from 'interfaces/IClient';
import { IOrder, IOrderBillingData, IOrderHistory } from 'interfaces/IOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useOrderHistoryService } from 'services/orderHistoryService';
import { useOrderService } from 'services/orderService';
import CardBillings from './components/CardBillings';
import TableOrderEdit from './components/TableOrderEdit';
import TableOrderHistory from './components/TableOrderHistory';
import * as S from './style';

interface IStateProps {
  client: IClient;
  order: IOrder;
}

const OrderDetail: React.FC = () => {
  const { client, order } = useLocation().state as IStateProps;
  const [cardShow, setCardShow] = useState('Estimated');
  const { orderService } = useOrderService();
  const { orderHistoryService } = useOrderHistoryService();
  const [ordersBilling, setOrdersBilling] = useState<IOrderBillingData | null>(
    null
  );

  const [ordersBillingEstimated, setOrdersBillingEstimated] =
    useState<IOrderBillingData | null>(null);

  const [ordersBillingPreviousMonth, setOrdersBillingPreviousMonth] =
    useState<IOrderBillingData | null>(null);

  const [orderHistory, setOrderHistory] = useState<IOrderHistory[] | null>(
    null
  );

  const handleCardShow = useCallback((cardName: string) => {
    setCardShow(prevState => (cardName === prevState ? '' : cardName));
  }, []);

  const getData = useCallback(async () => {
    try {
      const {
        ProductContractsBilling,
        ProductContractsBillingEstimated,
        ProductContractsBillingPreviousMonth
      } = await orderService.findOrderBillingByOrderId(order.Id);

      const { ProductContractHistories } =
        await orderHistoryService.findByProductContractId(order.Id);

      setOrderHistory(ProductContractHistories);

      setOrdersBilling(ProductContractsBilling);
      setOrdersBillingEstimated(ProductContractsBillingEstimated);
      setOrdersBillingPreviousMonth(ProductContractsBillingPreviousMonth);
    } catch (error) {
      console.log(`error in OrderDatail - function getData, error:${error}`);
    }
  }, [orderHistoryService, orderService, order.Id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <Title backButton>Detalhes do Pedido</Title>
      <Row>
        <Col xs={12}>
          <LabelCustom>Companhia</LabelCustom>
          <TextCustom>{client.CompanyName}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Razão Social</LabelCustom>
          <TextCustom>{client.Name}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>CNPJ</LabelCustom>
          <TextCustom>{client.Cnpj}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Nome Fantasia</LabelCustom>
          <TextCustom>{client.FantasyName}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Data de Cadastro</LabelCustom>
          <TextCustom>{client.CreatedDate}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Id Pedido</LabelCustom>
          <TextCustom>{order.Id}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Servidor</LabelCustom>
          <TextCustom>{order.ServerName}</TextCustom>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Allow ruleId={enumRules.Order.CanWriteOrder}>
          <Col lg={12} xl={6}>
            {ordersBilling && (
              <Card>
                <h2>Edição</h2>
                <TableOrderEdit data={ordersBilling} getData={getData} />
              </Card>
            )}
          </Col>
        </Allow>
        <Col lg={12} xl={6}>
          <Row>
            <Col>
              {ordersBillingPreviousMonth && (
                <CardBillings
                  cardShow={cardShow}
                  handleCardShow={handleCardShow}
                  data={ordersBillingPreviousMonth}
                  cardShowName="PreviousMonth"
                  title="Fechamento mês anterior"
                />
              )}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {ordersBillingEstimated && (
                <CardBillings
                  cardShow={cardShow}
                  handleCardShow={handleCardShow}
                  data={ordersBillingEstimated}
                  cardShowName="Estimated"
                  title="Estimado do mês"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={12}>
          {orderHistory && <TableOrderHistory data={orderHistory} />}
        </Col>
      </Row>
    </S.Container>
  );
};

export default OrderDetail;
