import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import { IClient } from 'interfaces/IClient';
import { IClientAdjustmentAdd } from 'interfaces/IClientAdjustment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClientAdjustmentService } from 'services/clientAdjustmentService';
import { Container } from './styles';

const AddClientAdjustment: React.FC = () => {
  const client = useLocation().state as IClient;
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const [validated, setValidated] = useState(false);
  const { clientAdjustmentService } = useClientAdjustmentService();
  const [clientAdjustmentAdd, setClientAdjustmentAdd] =
    useState<IClientAdjustmentAdd>({
      Year: new Date().getFullYear(),
      Month: new Date().getMonth() + 1,
      Value: 0
    } as IClientAdjustmentAdd);

  const handleConfirm = useCallback(async () => {
    try {
      clientAdjustmentAdd.ClientId = client.Id;
      await clientAdjustmentService.add(clientAdjustmentAdd);
      navigate(-1);
    } catch (error) {
      console.log(`error in AddRule - function handleConfirm, error:${error}`);
    }
  }, [clientAdjustmentService, client, clientAdjustmentAdd, navigate]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
      setValidated(!form.checkValidity());

      if (form.checkValidity())
        setDialog({ show: true, callBackConfirm: handleConfirm });
    },
    [handleConfirm, setDialog]
  );

  return (
    <Container>
      <Title backButton>Adicionar Ajuste por cliente</Title>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} xs={12} sm={6} controlId="validationCompanyName">
            <Form.Label>
              <h3>Razão Social</h3>
            </Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              defaultValue={client.Name}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6} controlId="validationFantasyName">
            <Form.Label>
              <h3>Nome Fantasia</h3>
            </Form.Label>
            <Form.Control
              type="text"
              name="fantasyName"
              defaultValue={client.FantasyName}
              disabled
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} xs={12} sm={6} controlId="validationName">
            <Form.Label>
              <h3>Ano</h3>
            </Form.Label>
            <Form.Control
              as="select"
              name="year"
              required
              defaultValue={new Date().getFullYear()}
              onChange={e =>
                setClientAdjustmentAdd(prevState => ({
                  ...prevState,
                  Year: parseInt(e.target.value, 10)
                }))
              }
            >
              {[...Array(5)].map((_, index) => {
                const year = new Date().getFullYear() - 2 + index;
                return (
                  <option key={year} value={year}>
                    {year}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Campo Ano é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6} controlId="validationMonth">
            <Form.Label>
              <h3>Mês</h3>
            </Form.Label>
            <Form.Control
              as="select"
              name="month"
              required
              defaultValue={new Date().getMonth() + 1}
              onChange={e =>
                setClientAdjustmentAdd(prevState => ({
                  ...prevState,
                  Month: parseInt(e.target.value, 10)
                }))
              }
            >
              {[...Array(12)].map((_, index) => {
                const month = index + 1;
                return (
                  <option key={month} value={month}>
                    {new Date(0, month - 1).toLocaleString('default', {
                      month: 'long'
                    })}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Campo Mês é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} xs={12} sm={6} controlId="validationType">
            <Form.Label>
              <h3>Tipo</h3>
            </Form.Label>
            <Form.Control
              as="select"
              name="type"
              required
              defaultValue=""
              onChange={e =>
                setClientAdjustmentAdd(prevState => ({
                  ...prevState,
                  Type: e.target.value
                }))
              }
            >
              <option value="">Selecione o tipo</option>
              <option value="Desconto">Desconto</option>
              <option value="Acréscimo">Acréscimo</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Campo Tipo é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6} controlId="validationValue">
            <Form.Label>
              <h3>Valor</h3>
            </Form.Label>
            <Form.Control
              type="number"
              name="value"
              required
              placeholder="Valor em dinheiro"
              onChange={e =>
                setClientAdjustmentAdd(prevState => ({
                  ...prevState,
                  Value: parseFloat(e.target.value)
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Valor é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} xs={12} controlId="validationObservation">
            <Form.Label>
              <h3>Observação</h3>
            </Form.Label>
            <Form.Control
              as="textarea"
              name="observation"
              defaultValue=""
              maxLength={100}
              placeholder="Observação (máximo de 100 caracteres)"
              onChange={e =>
                setClientAdjustmentAdd(prevState => ({
                  ...prevState,
                  Observation: e.target.value
                }))
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Campo Observação é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={12}>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default AddClientAdjustment;
