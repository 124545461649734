import Allow from 'components/Allow';
import FilterText from 'components/FilterText';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import { IProductGroup } from 'interfaces/IProduct';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useProductGroupService } from 'services/productGroupService';
import { Delete, Edit } from 'styled-icons/material';
import * as S from './style';

const TableProductGroups: React.FC = () => {
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const { productGroupService } = useProductGroupService();
  const [productGroups, setProductGroups] = useState<IProductGroup[] | null>(
    null
  );
  const getData = useCallback(async () => {
    try {
      const { ProductGroups } = await productGroupService.getAll();
      setProductGroups(ProductGroups);
    } catch (error) {
      console.log(
        `error in TableProductGroups - function getData, error:${error}`
      );
    }
  }, [productGroupService]);
  const handleConfirm = useCallback(
    async (productId: number) => {
      try {
        await productGroupService.destroy(productId);
        const { ProductGroups } = await productGroupService.getAll();
        setProductGroups(ProductGroups);
      } catch (error) {
        console.log(
          `error in TableProductGroups - function handleConfirm, error:${error}`
        );
      }
    },
    [productGroupService]
  );
  const handleDelete = (productGroupId: number) => {
    setDialog({
      show: true,
      callBackConfirm: () => handleConfirm(productGroupId),
      textBody: 'Esta ação é irreversível, tem certeza que deseja prosseguir ?'
    });
  };
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Id ou Nome..."
        nameFieldToFilter="Name,Id"
        dataToFilter={productGroups}
        setDataToFilter={setProductGroups}
      />
      {productGroups && productGroups.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {productGroups.map((productGroup: IProductGroup) => (
                    <tr key={productGroup.Id}>
                      <td>{productGroup.Id}</td>
                      <td>{productGroup.Name}</td>

                      <td>
                        <Allow
                          ruleId={enumRules.ProductGroup.CanWriteProductGroup}
                        >
                          <Button
                            onClick={() =>
                              navigate('AddEditProductGroup', {
                                state: productGroup
                              })
                            }
                            variant="outline-primary"
                          >
                            <Edit title="Editar" size={22} />
                          </Button>
                          <Button
                            style={{ margin: 5 }}
                            onClick={() => handleDelete(productGroup.Id)}
                            variant="outline-primary"
                          >
                            <Delete title="Excluir" size={22} />
                          </Button>
                        </Allow>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableProductGroups;
