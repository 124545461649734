import { Add } from '@styled-icons/fluentui-system-regular';
import Allow from 'components/Allow';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { IClient } from 'interfaces/IClient';
import { useLocation, useNavigate } from 'react-router-dom';
import { LabelCustom } from 'components/LabelCustom';
import { TextCustom } from 'components/TextCustom';
import TableClientAdjustments from './Components/TableClientAdjustments';
import { Container } from './styles';

export const ClientAdjustment: React.FC = () => {
  const client = useLocation().state as IClient;
  const navigate = useNavigate();
  return (
    <Container>
      <Title backButton>Ajustes financeiros</Title>
      <Row>
        <Col xs={12} md={3}>
          <LabelCustom>Companhia</LabelCustom>
          <TextCustom>{client.CompanyName}</TextCustom>
        </Col>
        <Col xs={12} md={3}>
          <LabelCustom>Razão Social</LabelCustom>
          <TextCustom>{client.Name}</TextCustom>
        </Col>
        <Col xs={12} md={3}>
          <LabelCustom>Nome Fantasia</LabelCustom>
          <TextCustom>{client.FantasyName}</TextCustom>
        </Col>
        <Col xs={12} md={3}>
          <LabelCustom>CNPJ</LabelCustom>
          <TextCustom>{client.Cnpj}</TextCustom>
        </Col>
      </Row>
      <br />
      <Row>
        <Allow ruleId={enumRules.ClientAdjustment.CanWriteClientAdjustment}>
          <Col xs={12} sm={6}>
            <Button
              variant="outline-primary"
              title="Adicionar Ajuste financeiro"
              onClick={() =>
                navigate('addAdjustment', {
                  state: client
                })
              }
            >
              <Add size="20" />
            </Button>
          </Col>
        </Allow>
      </Row>
      <br />
      <TableClientAdjustments />
    </Container>
  );
};
