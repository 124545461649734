import {
  IClientAdjustment,
  IClientAdjustmentAdd
} from 'interfaces/IClientAdjustment';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useClientAdjustmentService = () => {
  const { callApi } = useFetch();

  interface IClientAdjustmentResponse {
    ClientAdjustments: IClientAdjustment[];
  }

  const findClientAdjustmentByClientId = useCallback(
    async (clientId: number) => {
      const result = await callApi<IClientAdjustmentResponse>({
        url: `ClientAdjustment/FindClientAdjustmentByClientId/${clientId}`,
        method: 'get',
        prefixMsgError: 'error in ClientAdjustmentService - getAll:'
      });
      return result;
    },
    [callApi]
  );

  const findOne = useCallback(
    async (clientAdjustmentId: number) => {
      const result = await callApi<IClientAdjustment>({
        url: `ClientAdjustment/FindClientAdjustmentByClientAdjustmentId/${clientAdjustmentId}`,
        method: 'get',
        prefixMsgError: 'error in ClientAdjustmentService - findOne:'
      });
      return result;
    },
    [callApi]
  );

  const add = useCallback(
    async (product: IClientAdjustmentAdd) => {
      await callApi({
        url: 'ClientAdjustment/Add',
        method: 'post',
        data: product,
        prefixMsgError: 'error in ClientAdjustmentService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (clientAdjustment: IClientAdjustment) => {
      await callApi({
        url: `ClientAdjustment/Update/${clientAdjustment.ClientAdjustmentId}`,
        method: 'put',
        data: clientAdjustment,
        prefixMsgError: 'error in ClientAdjustmentService - update:'
      });
    },
    [callApi]
  );

  const destroy = useCallback(
    async (clientAdjustmentId: number) => {
      await callApi({
        url: `ClientAdjustment/Delete/${clientAdjustmentId}`,
        method: 'delete',
        prefixMsgError: 'error in ClientAdjustmentService - destroy:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      clientAdjustmentService: {
        findClientAdjustmentByClientId,
        findOne,
        add,
        update,
        destroy
      }
    }),
    [findClientAdjustmentByClientId, add, findOne, update, destroy]
  );
};
