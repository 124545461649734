import Allow from 'components/Allow';
import FilterText from 'components/FilterText';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import { IProduct } from 'interfaces/IProduct';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useProductService } from 'services/productService';
import { Delete, Edit } from 'styled-icons/material';
import * as S from './style';

const TableProducts: React.FC = () => {
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const { productService } = useProductService();
  const [products, setProducts] = useState<IProduct[] | null>(null);
  const getData = useCallback(async () => {
    try {
      const { Products } = await productService.getAll();
      setProducts(Products);
    } catch (error) {
      console.log(`error in TableProducts - function getData, error:${error}`);
    }
  }, [productService]);
  const handleConfirm = useCallback(
    async (productId: number) => {
      try {
        await productService.destroy(productId);
        const { Products } = await productService.getAll();
        setProducts(Products);
      } catch (error) {
        console.log(
          `error in AddProduct - function handleConfirm, error:${error}`
        );
      }
    },
    [productService]
  );
  const handleDelete = (productId: number) => {
    setDialog({
      show: true,
      callBackConfirm: () => handleConfirm(productId),
      textBody: 'Esta ação é irreversível, tem certeza que deseja prosseguir ?'
    });
  };
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Id, Nome, Nome Interno, Tipo de Produto..."
        nameFieldToFilter="Name,InternalName,ProductTypeName,ProductGroupName,ProductBillingName,CreatedDate,Id"
        dataToFilter={products}
        setDataToFilter={setProducts}
      />
      {products && products.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Apelido</th>
                    <th>Dt. Criação</th>
                    <th>Tipo</th>
                    <th>Grupo</th>
                    <th>Faturamento</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product: IProduct) => (
                    <tr key={product.Id}>
                      <td>{product.Id}</td>
                      <td>{product.Name}</td>
                      <td>{product.InternalName}</td>
                      <td>
                        {product.CreatedDate.toDate().toLocaleDateString()}
                      </td>
                      <td>{product.ProductTypeName}</td>
                      <td>{product.ProductGroupName}</td>
                      <td>{product.ProductBillingName}</td>
                      <td>
                        <Allow ruleId={enumRules.Product.CanWriteProduct}>
                          <Button
                            onClick={() =>
                              navigate('AddEditProduct', { state: product })
                            }
                            variant="outline-primary"
                          >
                            <Edit title="Editar" size={22} />
                          </Button>
                          <Button
                            style={{ margin: 5 }}
                            onClick={() => handleDelete(product.Id)}
                            variant="outline-primary"
                          >
                            <Delete title="Excluir" size={22} />
                          </Button>
                        </Allow>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableProducts;
