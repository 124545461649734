import { IProductGroup } from 'interfaces/IProduct';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useProductGroupService = () => {
  const { callApi } = useFetch();

  interface IProductGroupResponse {
    ProductGroups: IProductGroup[];
  }

  const getAll = useCallback(async () => {
    const result = await callApi<IProductGroupResponse>({
      url: `ProductGroup/GetAll`,
      method: 'get',
      prefixMsgError: 'error in ProductGroupService - getAll:'
    });
    return result;
  }, [callApi]);

  const findOne = useCallback(
    async (productGroupId: number) => {
      const result = await callApi<IProductGroup>({
        url: `ProductGroup/FindProductGroupByProductGroupId/${productGroupId}`,
        method: 'get',
        prefixMsgError: 'error in ProductGroupService - findOne:'
      });
      return result;
    },
    [callApi]
  );

  const add = useCallback(
    async (product: IProductGroup) => {
      await callApi({
        url: 'ProductGroup/Add',
        method: 'post',
        data: product,
        prefixMsgError: 'error in ProductGroupService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (productGroup: IProductGroup) => {
      await callApi({
        url: `ProductGroup/Update/${productGroup.Id}`,
        method: 'put',
        data: productGroup,
        prefixMsgError: 'error in ProductGroupService - update:'
      });
    },
    [callApi]
  );

  const destroy = useCallback(
    async (productGroupId: number) => {
      await callApi({
        url: `ProductGroup/Delete/${productGroupId}`,
        method: 'delete',
        prefixMsgError: 'error in ProductGroupService - destroy:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      productGroupService: {
        getAll,
        findOne,
        add,
        update,
        destroy
      }
    }),
    [getAll, add, findOne, update, destroy]
  );
};
