import { Card } from 'components/Card';
import { LabelCustom } from 'components/LabelCustom';
import { TextCustom } from 'components/TextCustom';
import Title from 'components/Title';
import {
  IClient,
  IClientOrganizationTypes,
  IClientStates,
  IClientUpdate
} from 'interfaces/IClient';
import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { useMainContext } from 'context/MainContext';
import { Button, Col, Form, FormLabel, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClientService } from 'services/clientService';
import * as S from './styles';

const ClientDetails: React.FC = () => {
  const { clientService } = useClientService();
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const [client, setClient] = useState(useLocation().state as IClient);
  const [organizationTypes, setOrganizationTypes] = useState<
    IClientOrganizationTypes[]
  >([] as IClientOrganizationTypes[]);
  const [clientStates, setClientStates] = useState<IClientStates[]>(
    [] as IClientStates[]
  );
  const [show, setShow] = useState(false);
  const [type, setType] = useState<number>(0);
  const [state, setState] = useState<number>(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleConfirm = useCallback(async () => {
    const obj: IClientUpdate = {
      clientId: client.Id,
      clientStateId: state === 0 ? null : state,
      clientOrganizationTypeId: type === 0 ? null : type
    };
    try {
      await clientService.updateClient([obj], client.Id);
      const result = await clientService.findClientByCnpjOrName(client.Cnpj);
      setClient(result.filter(a => a.Id === client.Id)[0]);
      setShow(false);
    } catch (error) {
      console.log(
        `error in UpdateClient - function handleConfirm, error:${error}`
      );
    }
  }, [client.Id, clientService, client.Cnpj, state, type]);

  const handleSubmit = useCallback(() => {
    handleClose();
    setDialog({
      show: true,
      callBackConfirm: handleConfirm,
      callBackCancel: handleShow
    });
  }, [handleConfirm, setDialog]);

  const openModal = () => {
    const resultType = organizationTypes.filter(
      a => a.Name === client.OrganizationTypeName
    );
    if (resultType.length > 0) {
      setType(resultType[0].Id);
    } else {
      setType(0);
    }

    const resultState = clientStates.filter(a => a.Name === client.StateName);
    if (resultState.length > 0) {
      setState(resultState[0].Id);
    } else {
      setState(0);
    }
    handleShow();
  };

  const getData = useCallback(async () => {
    const { ClientOrganizationTypes } =
      await clientService.getAllOrganizationType();
    const { ClientStates } = await clientService.getAllClientStates();
    setOrganizationTypes(ClientOrganizationTypes);
    setClientStates(ClientStates);
  }, [clientService]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <S.Container>
      {/* modal */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Informações do Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col xs={2}>
              <FormLabel>Status:</FormLabel>
            </Col>
            <Col xs={10}>
              <Form.Select
                onChange={a => setState(Number(a.target.value))}
                defaultValue={state}
              >
                <option
                  selected={state === 0}
                  label="Não Informado"
                  value={0}
                />
                {clientStates.map(a => (
                  <option
                    selected={state === a.Id}
                    key={a.Id}
                    label={a.Name}
                    value={a.Id}
                  />
                ))}
              </Form.Select>
            </Col>
          </Row>
          <br />
          <Row className="align-items-center">
            <Col xs={2}>
              <Form.Label xs={6}>Tipo</Form.Label>
            </Col>
            <Col xs={10}>
              <Form.Select
                onChange={a => setType(Number(a.target.value))}
                defaultValue={type}
              >
                <option selected={type === 0} label="Não Informado" value={0} />
                {organizationTypes.map(a => (
                  <option
                    selected={type === a.Id}
                    key={a.Id}
                    label={a.Name}
                    value={a.Id}
                  />
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* fim do modal */}
      <Title backButton>Detalhes do Cliente</Title>
      <Row>
        <Col xs={11}>
          <LabelCustom>Companhia</LabelCustom>
          <TextCustom>{client.CompanyName}</TextCustom>
        </Col>
        <Col xs={1}>
          <S.EditButton
            title="Editar Cliente"
            className="btn-block"
            onClick={openModal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Razão Social</LabelCustom>
          <TextCustom>{client.Name}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Data de Cadastro</LabelCustom>
          <TextCustom>{client.CreatedDate}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>Nome Fantasia</LabelCustom>
          <TextCustom>{client.FantasyName}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Status</LabelCustom>
          <TextCustom>{client.StateName}</TextCustom>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <LabelCustom>CNPJ</LabelCustom>
          <TextCustom>{client.Cnpj}</TextCustom>
        </Col>
        <Col xs={12} md={6}>
          <LabelCustom>Tipo</LabelCustom>
          <TextCustom>{client.OrganizationTypeName}</TextCustom>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={3}>
          <Card>
            <Row>
              <Col className="text-center">
                <LabelCustom>Este cliente possui</LabelCustom>
                <S.NumberOrders>{client.OrdersNumber}</S.NumberOrders>
                <LabelCustom>Pedidos</LabelCustom>
                <Button
                  className="btn-block"
                  onClick={() => navigate(`./orders`, { state: client })}
                >
                  Detalhes
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </S.Container>
  );
};

export default ClientDetails;
