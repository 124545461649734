import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import {
  IProduct,
  IProductType,
  IProductGroup,
  IProductBilling
} from 'interfaces/IProduct';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProductService } from 'services/productService';
import { useProductTypeService } from 'services/productTypeService';
import { useProductGroupService } from 'services/productGroupService';
import { useProductBillingService } from 'services/productBillingService';
import { Container } from './styles';

const AddEditProduct: React.FC = () => {
  const productEdit = useLocation().state as IProduct;
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const [validated, setValidated] = useState(false);
  const { productService } = useProductService();
  const { productTypeService } = useProductTypeService();
  const { productGroupService } = useProductGroupService();
  const { productBillingService } = useProductBillingService();

  // form consts
  const [products, setProducts] = useState<IProduct[]>([] as IProduct[]);

  const [productUppers, setProductUppers] = useState<IProduct[]>(
    [] as IProduct[]
  );

  const [productTypes, setProductTypes] = useState<IProductType[]>(
    [] as IProductType[]
  );

  const [productGroups, setProductGroups] = useState<IProductGroup[]>(
    [] as IProductGroup[]
  );

  const [productBillings, setProductBillings] = useState<IProductBilling[]>(
    [] as IProductBilling[]
  );

  // object
  const [productAddEdit, setProductAddEdit] = useState<IProduct>(
    productEdit?.Id ? productEdit : ({} as IProduct)
  );

  const handleConfirm = useCallback(async () => {
    try {
      if (productEdit?.Id) {
        await productService.update(productAddEdit);
      } else await productService.add(productAddEdit);
      navigate(-1);
    } catch (error) {
      console.log(
        `error in AddProduct - function handleConfirm, error:${error}`
      );
    }
  }, [productEdit?.Id, productService, productAddEdit, navigate]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
      setValidated(!form.checkValidity());

      if (form.checkValidity())
        setDialog({ show: true, callBackConfirm: handleConfirm });
    },
    [handleConfirm, setDialog]
  );

  const getData = useCallback(async () => {
    try {
      const { ProductTypes } = await productTypeService.getAll();
      const { ProductGroups } = await productGroupService.getAll();
      const { ProductBillings } = await productBillingService.getAll();
      const { Products } = await productService.getAll();
      setProducts(Products);
      setProductTypes(ProductTypes);
      setProductGroups(ProductGroups);
      setProductBillings(ProductBillings);
      setProductUppers(
        Products.filter(
          a => a.ProductTypeId === productAddEdit.ProductTypeId
        ).filter(a => a.Id !== productEdit?.Id)
      );
    } catch (error) {
      console.log(`error in TableProducts - function getData, error:${error}`);
    }
  }, [
    productTypeService,
    productGroupService,
    productBillingService,
    productService,
    productAddEdit.ProductTypeId,
    productEdit?.Id
  ]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <Container>
      <Title backButton>
        {' '}
        {productEdit?.Id ? 'Editar' : 'Adicionar'} Produto{' '}
      </Title>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} xs={6} controlId="validationName">
            <Form.Label>
              <h3>Nome</h3>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              placeholder="Nome"
              defaultValue={productAddEdit?.Name}
              onChange={e =>
                setProductAddEdit(prevState => ({
                  ...prevState,
                  Name: e.target.value
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Nome é Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={6} controlId="validationInternalName">
            <Form.Label>
              <h3>Nome Interno</h3>
            </Form.Label>
            <Form.Control
              type="text"
              name="internalName"
              required
              placeholder="Nome Interno"
              defaultValue={productAddEdit?.InternalName}
              onChange={e =>
                setProductAddEdit(prevState => ({
                  ...prevState,
                  InternalName: e.target.value
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Nome Interno é Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationProductType">
            <Form.Label>Tipo de Produto</Form.Label>
            <Form.Select
              name="select"
              required
              onChange={a =>
                setProductAddEdit(prevState => ({
                  ...prevState,
                  ProductTypeId: Number(a.target.value)
                }))
              }
              defaultValue={productAddEdit?.ProductTypeId || 0}
            >
              <option
                selected={productAddEdit?.ProductTypeId === null}
                label="Selecione"
                value={undefined}
              />
              {productTypes.map(a => (
                <option
                  selected={productAddEdit?.ProductTypeId === a.Id}
                  key={a.Id}
                  value={a.Id}
                >
                  {a.Name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Tipo de Produto é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationUpperProduct">
            <Form.Label>Produto Pai</Form.Label>
            <Form.Select
              onChange={a =>
                setProductAddEdit(prevState => ({
                  ...prevState,
                  ProductUpperId:
                    Number(a.target.value) === 0 ? null : Number(a.target.value)
                }))
              }
              defaultValue={productAddEdit?.ProductUpperId || 0}
            >
              <option
                selected={productAddEdit?.ProductUpperId === 0}
                label="Não Informado"
                value={0}
              />
              {productUppers.map(a => (
                <option
                  selected={productAddEdit?.ProductUpperId === a.Id}
                  key={a.Id}
                  value={a.Id}
                >{`${a.Name} - ${a.ProductTypeName}`}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationProductGroup">
            <Form.Label>Grupo do Produto</Form.Label>
            <Form.Select
              name="select"
              required
              onChange={a =>
                setProductAddEdit(prevState => ({
                  ...prevState,
                  ProductGroupId: Number(a.target.value)
                }))
              }
              defaultValue={productAddEdit?.ProductGroupId || 0}
            >
              <option
                selected={productAddEdit?.ProductGroupId === null}
                label="Selecione"
                value={undefined}
              />
              {productGroups.map(a => (
                <option
                  selected={productAddEdit?.ProductGroupId === a.Id}
                  key={a.Id}
                  value={a.Id}
                >
                  {a.Name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Grupo de Produto é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationProductType">
            <Form.Label>Modo de Faturamento do Produto</Form.Label>
            <Form.Select
              name="select"
              required
              onChange={a =>
                setProductAddEdit(prevState => ({
                  ...prevState,
                  ProductBillingId: Number(a.target.value)
                }))
              }
              defaultValue={productAddEdit?.ProductBillingId || 0}
            >
              <option
                selected={productAddEdit?.ProductBillingId === null}
                label="Selecione"
                value={undefined}
              />
              {productBillings.map(a => (
                <option
                  selected={productAddEdit?.ProductBillingId === a.Id}
                  key={a.Id}
                  value={a.Id}
                >
                  {a.Name}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Modo de Faturamento do Produto é obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Col xs={12}>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
      <Row />
    </Container>
  );
};

export default AddEditProduct;
