import Logout from 'components/Logout';
import Profile from 'components/Profile';
import { useMainContext } from 'context/MainContext';
import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';

const Header: React.FC = () => {
  const { setShowMenu, showMenu } = useMainContext();
  const [changeIcon, setChangeIcon] = useState(false);

  const handleShowMenu = useCallback(() => {
    setShowMenu(prevState => {
      const show = !prevState;
      document.body.classList.toggle('overflow-hidden', show);
      setChangeIcon(show);
      return show;
    });
  }, [setShowMenu]);

  useEffect(() => {
    setChangeIcon(showMenu);
  }, [showMenu]);

  return (
    <S.Container>
      <S.WrapperMenuICon onClick={handleShowMenu}>
        {changeIcon ? <S.MenuIConClose /> : <S.MenuIConOpen />}
      </S.WrapperMenuICon>
      <S.LogoCompany
        src="/images/Logo_Golden_fundobranco.png"
        alt="logo-company"
      />
      <S.WrapperRight>
        <Profile />
        <Logout />
      </S.WrapperRight>
    </S.Container>
  );
};

export default Header;
